@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Variable
// ==================================================
$body-min-width-pc: 1360px;
$navigation-width-pc: 320px;
$transition-setting: 0.3s ease;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  // Mainvisual
  .mainvisual {
    display: block;
    width: calc(100% - $navigation-width-pc);
    height: 100vh;
    min-height: 845px;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    @include mixin.pc {
      min-width: calc($body-min-width-pc - $navigation-width-pc);
      transform: translateX($navigation-width-pc);
    }

    @include mixin.sp {
      width: 100%;
      height: 100svh;
      min-height: 600px;
    }

    &_text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 29px;
      position: absolute;
      z-index: 2;
      bottom: max(22.3vh, 188px);
      left: 57px;
      margin: 0;

      @include mixin.sp {
        row-gap: 18px;
        left: 26px;
        bottom: 143px;
      }

      &_item {
        display: block;
        font-size: 4rem;
        line-height: calc(58 / 40);
        background-color: color.$bg-white;
        padding: 2px 22px 3px;

        @include mixin.sp {
          font-size: 2.3rem;
          padding: 4px 12px 5px;
        }

        &:last-of-type {
          &::after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            transform: translateY(-4px);
            width: 37px;
            height: 3px;
            border-radius: 18px;
            background-color: color.$site-write;
            margin-left: 9px;

            @include mixin.sp {
              width: 24px;
              height: 2.5px;
              margin-left: 6px;
              transform: translateY(-3px);
            }
          }
        }
      }
    }

    &_slider {
      display: block;
      width: 100%;
      height: 100%;

      &_item {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      // Javascript
      &-js {
        // Slick
        &.slick-initialized {
          display: block;
        }
        .slick-list,
        .slick-track {
          height: 100%;
        }
      }
    }
  }

  // About
  .about {
    display: block;
    width: 100%;
    padding: 80px 0 0;
    margin-top: max(100vh, 845px);
    position: relative;
    z-index: 1;

    @include mixin.sp {
      margin-top: max(100svh, 600px);
      padding: 60px 0 0;
    }

    &::after {
      content: "";
      display: block;
      width: 383px;
      height: auto;
      aspect-ratio: 1/0.91;
      background-image: url(../img/common/bg_leaf_bottom.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      z-index: -1;
      bottom: -65px;
      left: -3px;

      @include mixin.sp {
        width: 191px;
        bottom: -40px;
        left: 30px;
      }
    }

    .main_contentInner_title {
      @include mixin.pc {
        padding-top: 13px;
      }

      &::before {
        @include mixin.pc {
          height: calc(100% - 13px);
          top: 13px;
        }
      }
    }

    .main_contentInner_content {
      position: relative;
      padding-bottom: 104px;

      @include mixin.sp {
        padding-bottom: 0;
      }
    }

    &_title {
      font-size: 3rem;
      line-height: calc(51 / 30);
      margin: 0 0 55px;

      @include mixin.sp {
        font-size: 2rem;
        margin-bottom: 30px;
      }
    }

    &_paragraph {
      font-size: 1.6rem;
      line-height: calc(25 / 16);
      margin: 30px 0 0;

      @include mixin.sp {
        font-size: 1.4rem;
        margin-top: 20px;
      }
    }

    &_photo {
      display: block;
      width: 277px;

      @include mixin.pc {
        position: absolute;
        right: 0;
        bottom: 60px;
      }

      @include mixin.sp {
        width: calc(277px / 2);
        margin-top: 15px;
        margin-left: auto;
        transform: translateX(-30px);
      }
    }
  }

  // Message
  .message {
    display: block;
    width: 100%;
    padding: 80px 0 0 20px;
    position: relative;
    z-index: 1;

    @include mixin.sp {
      padding: 60px 0 0 0;
    }

    &::after {
      content: "";
      display: block;
      width: 249px;
      height: auto;
      aspect-ratio: 1/0.819;
      background-image: url(../img/common/bg_leaf.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      z-index: -1;
      left: calc(50% + 270px);
      bottom: 7px;
      transform: translateX(-50%);

      @include mixin.sp {
        width: 124px;
        left: 50px;
        bottom: -25px;
      }
    }

    &_title {
      font-size: 2.1rem;
      line-height: calc(30 / 21);
      margin: 0 0 18px;
      text-align: center;

      @include mixin.sp {
        font-size: 2.2rem;
        margin: 0 0 20px;
      }
    }

    &_youtube {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto;

      @include mixin.pc {
        max-width: 591px;
        aspect-ratio: 1/0.562;
      }

      & > iframe {
        display: block;

        @include mixin.pc {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @include mixin.sp {
          width: 100% !important;
          height: auto !important;
          aspect-ratio: 1/0.562;
        }
      }
    }

    .main_linkButton {
      text-align: center;
      &_item {
        margin-top: 41px;

        @include mixin.sp {
          margin-top: 30px;
        }
      }
    }
  }

  // Method
  .method {
    display: block;
    width: 100%;
    padding: 80px 0 340px;

    @include mixin.sp {
      padding: 60px 0 0;
    }

    .main_contentInner_content {
      position: relative;
      z-index: 1;
    }

    &_worries {
      display: block;
      width: 100%;
      list-style: none;
      margin: 118px 0 0;
      padding: 70px 0 206px;
      position: relative;

      @include mixin.sp {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
        margin: 0;
        padding: 0;
      }

      &_item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        border-radius: 37px;
        text-align: center;
        font-size: 1.7rem;
        white-space: nowrap;
        line-height: calc(25 / 17);
        background-color: color.$bg-method-worries;

        @include mixin.pc {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        @include mixin.sp {
          font-size: 1.3rem;
          height: auto;
          padding: 4px 16px 6px;
          transform: rotate(-2deg);
        }

        &:nth-of-type(odd) {
          @include mixin.sp {
            margin-left: auto;
            transform: rotate(2deg);
          }
        }

        &:nth-of-type(1) {
          @include mixin.pc {
            width: 220px;
            left: calc(50% - 52px);
          }
        }
        &:nth-of-type(2) {
          @include mixin.pc {
            width: 287px;
            top: 134px;
            left: calc(50% - 214px);
          }
        }
        &:nth-of-type(3) {
          @include mixin.pc {
            width: 273px;
            top: 272px;
            left: calc(50% - 264px);
          }
        }
        &:nth-of-type(4) {
          @include mixin.pc {
            width: 233px;
            top: 390px;
            left: calc(50% - 253px);
          }
        }
        &:nth-of-type(5) {
          @include mixin.pc {
            width: 266px;
            top: 84px;
            left: calc(50% + 226px);
            padding-left: 5em;
            text-indent: -6.5em;
          }
        }
        &:nth-of-type(6) {
          @include mixin.pc {
            width: 258px;
            top: 205px;
            left: calc(50% + 307px);
            padding-left: 6.25em;
            text-indent: -7.25em;
          }
        }
        &:nth-of-type(7) {
          @include mixin.pc {
            width: 225px;
            top: 344px;
            left: calc(50% + 286px);
          }
        }
      }
    }

    &_symbol {
      display: block;
      width: 225px;
      margin: 0 auto 37px;

      @include mixin.sp {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0.3;
      }
    }

    &_title {
      display: block;
      text-align: center;
      font-size: 3rem;
      line-height: calc(51 / 30);
      margin: 0 0 219px;

      @include mixin.sp {
        font-size: 2.6rem;
        line-height: calc(44 / 26);
        margin: 15px 0 0;
      }

      &_inner {
        display: inline;
        background: linear-gradient(transparent 55%, #fff35d 0%);
        padding: 0 0.25em;
      }
    }

    &_before {
      display: block;
      width: 274px;
      position: absolute;

      @include mixin.pc {
        bottom: 215px;
        left: calc(50% - 260px);
        transform: translateX(-50%);
      }

      @include mixin.sp {
        width: calc(215px / 2.4);
        left: -6px;
        bottom: 82px;
      }
    }

    &_after {
      display: block;
      width: 214px;
      position: absolute;

      @include mixin.pc {
        left: calc(50% + 260px);
        bottom: 215px;
        transform: translateX(-50%);
      }

      @include mixin.sp {
        width: calc(214px / 3);
        right: 5px;
        bottom: 85px;
      }
    }

    &_logo {
      display: block;
      width: 359px;
      margin: 0 auto 37px;

      @include mixin.sp {
        width: 230px;
        margin: 10px auto -15px;
      }
    }
  }

  // Menu
  .menu {
    display: block;
    width: 100%;
    padding: 80px 0 207px;

    @include mixin.sp {
      padding: 60px 0 0;
    }

    &_box {
      &_item {
        display: flex;
        width: 100%;
        margin-top: 255px;
        position: relative;
        z-index: 1;

        @include mixin.sp {
          flex-direction: column;
          margin-top: 50px;
          row-gap: 20px;
        }

        &:first-of-type {
          margin-top: 0;
        }

        &::after {
          content: "";
          display: block;
          width: 249px;
          height: auto;
          aspect-ratio: 1/0.819;
          background-image: url(../img/common/bg_leaf.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          position: absolute;
          z-index: -1;
          top: -150px;
          right: -10px;

          @include mixin.sp {
            width: 124px;
            top: 80px;
            right: 0;
          }
        }

        &_image {
          display: flex;
          flex-direction: column;
          row-gap: 23px;
          width: 294px;

          @include mixin.sp {
            flex-direction: row;
            width: 100%;
            column-gap: 10px;
            row-gap: 10px;
          }

          &_item {
            display: block;
            width: 100%;
            border-radius: 17px;
            overflow: hidden;

            @include mixin.sp {
              width: calc(50% - 6px);
              border-radius: 10px;
            }

            & > img {
              display: block;
              width: 100%;
            }
          }
        }

        &_text {
          display: block;
          width: calc(100% - 294px);
          padding-left: 29px;

          @include mixin.sp {
            width: 100%;
            padding-left: 0;
          }

          & > :first-child {
            margin-top: 0 !important;
          }
          & > :last-child {
            margin-bottom: 0 !important;
          }

          &_head {
            display: block;
            width: calc(100% + 29px);
            padding-left: 29px;
            padding-bottom: 17px;
            border-bottom: 1px solid color.$site-write;
            margin-left: -29px;

            @include mixin.sp {
              width: 100%;
              padding-left: 0;
              padding-bottom: 12px;
              margin-left: 0;
            }

            &_name {
              display: inline-block;
              vertical-align: middle;
              font-size: 2.4rem;
              line-height: calc(28 / 24);
              letter-spacing: 0.1em;
              margin: 0;

              @include mixin.sp {
                font-size: 1.8rem;
              }
            }

            &_time {
              display: inline-block;
              vertical-align: middle;
              min-width: 89px;
              text-align: center;
              font-family: sans-serif;
              font-weight: 600;
              font-size: 1.6rem;
              line-height: calc(18 / 16);
              color: color.$write-white;
              background-color: color.$site-write;
              border-radius: 10px;
              padding: 4px 8px;
              transform: translateY(2px);
              margin-left: 29px;

              @include mixin.sp {
                min-width: 55px;
                font-size: 1.3rem;
                margin-left: 14px;
                padding: 3px 6px;
                transform: translateY(3px);
              }
            }
          }

          &_description {
            max-width: 420px;
            font-size: 1.3rem;
            line-height: calc(17 / 13);
            letter-spacing: -0.025em;
            color: color.$write-black;
            margin-top: 21px;
            margin-bottom: 41px;

            @include mixin.sp {
              max-width: none;
              margin-top: 20px;
              margin-bottom: 25px;
            }
          }

          &_detail {
            display: flex;
            align-items: center;
            margin: 19px 0 0;

            @include mixin.sp {
              flex-wrap: wrap;
              margin-top: 20px;
              padding: 0 15px;
            }

            &_name {
              display: block;
              min-width: 171px;
              font-size: 1.6rem;
              line-height: calc(22 / 16);
              text-align: center;
              padding: 0 2px 2px;
              border-radius: 10px;
              border: 1px solid color.$site-write;
              background-color: color.$bg-menu-label;

              @include mixin.sp {
                margin-right: 100%;
                font-size: 1.4rem;
                padding: 2px 4px 4px;
                margin-bottom: 12px;
              }
            }

            &_price {
              display: block;
              font-family: sans-serif;
              font-weight: 600;
              color: color.$write-black;
              font-size: 2.4rem;
              line-height: calc(27 / 24);
              margin-left: 37px;
              transform: translateY(1px);

              @include mixin.sp {
                font-size: 2.4rem;
                margin-left: 10px;
              }
            }

            &_note {
              display: block;
              font-size: 1.4rem;
              line-height: calc(20 / 14);
              margin-left: 10px;

              @include mixin.sp {
                margin-left: 8px;
              }
            }

            &_campaignPrice {
              display: block;
              font-family: sans-serif;
              font-weight: 600;
              font-size: 3.3rem;
              line-height: calc(27 / 24);
              transform: translateY(1px);
              padding-left: 44px;
              position: relative;

              @include mixin.sp {
                font-size: 2.8rem;
                padding-left: 38px;
              }

              &::before {
                content: "";
                display: block;
                width: 27px;
                height: auto;
                aspect-ratio: 1/0.185;
                background-image: url(../img/common/ico_arrow_right.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                position: absolute;
                z-index: -1;
                top: 15px;
                left: 10px;

                @include mixin.sp {
                  width: 21px;
                  top: 14px;
                }
              }
            }
          }

          &_label {
            font-size: 1.6rem;
            line-height: calc(24 / 16);
            color: color.$write-white;
            background-color: color.$site-write;
            padding: 0 24px 2px;
            border-radius: 10px;
            margin: 26px 0 0;

            @include mixin.sp {
              font-size: 1.4rem;
              padding: 2px 6px 4px;
              text-align: center;
              margin-top: 20px;
              margin-bottom: -5px;
            }
          }

          &_linkButton {
            display: block;
            text-align: right;

            @include mixin.sp {
              text-align: center;
              margin-top: 20px;
            }

            &_item {
              display: inline-block;
              width: 100%;
              max-width: 190px;
              margin: 44px 0 0 29px;
              font-size: 1.5rem;
              line-height: calc(21 / 15);
              text-align: center;
              color: color.$write-white;
              background-color: color.$site-write;
              border-radius: 27px;
              padding: 16px 0 15px;
              text-decoration: none;
              position: relative;
              transform: translateX(-8px);

              @include mixin.sp {
                min-width: 240px;
                margin-top: 15px;
                font-size: 1.6rem;
                padding: 13px 24px 12px;
              }

              &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                height: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                transform: translateY(-2px);
                margin-right: 8px;
              }

              &::after {
                content: "";
                display: block;
                width: 25px;
                height: 2px;
                border-radius: 13px;
                background-color: color.$site-write;
                position: absolute;
                top: 50%;
                right: -13px;
                transition: right $transition-setting;

                @include mixin.sp {
                  right: -12px;
                }
              }

              @include mixin.hover {
                &::after {
                  right: -23px;
                }
              }

              // Modifier
              &-reserve {
                &::before {
                  width: 23px;
                  aspect-ratio: 1/0.913;
                  background-image: url(../img/common/ico_reserve.svg);

                  @include mixin.sp {
                    width: 21px;
                  }
                }
              }
              &-cart {
                &::before {
                  width: 20px;
                  aspect-ratio: 1/0.9;
                  background-image: url(../img/common/ico_cart.svg);

                  @include mixin.sp {
                    width: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // Instagram
  .instagram {
    display: block;
    width: 100%;
    padding: 80px 0 102px;
    position: relative;

    @include mixin.sp {
      padding: 60px 0 60px;
      z-index: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 290px;
      height: auto;
      aspect-ratio: 1/0.91;
      background-image: url(../img/common/bg_leaf_bottom.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      position: absolute;
      bottom: -19px;
      left: 29px;

      @include mixin.sp {
        width: 145px;
        bottom: -60px;
        left: 0;
        z-index: -1;
      }
    }

    .main_contentInner_title {
      @include mixin.pc {
        padding-top: 103px;
      }

      &::before {
        @include mixin.pc {
          height: calc(100% - 103px);
          top: 103px;
        }
      }
    }

    .main_contentInner_content {
      @include mixin.pc {
        padding-bottom: 170px;
      }
    }

    &_title {
      font-size: 4rem;
      line-height: calc(58 / 40);
      text-align: center;
      margin: 0 0 45px;

      @include mixin.sp {
        font-size: 2.2rem;
        margin-bottom: 20px;
      }
    }

    &_feed {
      display: block;
      width: 100%;
    }

    .main_linkButton {
      text-align: center;
    }
  }

  // Access
  .access {
    display: block;
    width: 100%;
    padding: 80px 0;
    position: relative;

    @include mixin.sp {
      padding: 60px 0;
    }

    &_image {
      display: none;

      @include mixin.sp {
        display: block;
        width: 208px;
        margin: 0 auto 40px;
      }

      & > img {
        display: block;
        width: 100%;
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;

      @include mixin.sp {
        flex-direction: column;
        margin-top: 20px;
      }

      &_table {
        display: block;
        width: calc(100% - 208px - 58px);
        margin: 0;
        padding: 0;

        @include mixin.sp {
          width: 100%;
        }

        &_tr {
          display: flex;
          column-gap: 16px;
          font-size: 1.6rem;
          line-height: calc(20 / 16);

          @include mixin.sp {
            column-gap: 12px;
            font-size: 1.4rem;
            line-height: calc(20 / 14);
          }

          &:first-of-type {
            .access_content_table_th,
            .access_content_table_td {
              @include mixin.sp {
                border-top: 1px solid color.$site-main;
              }
            }
          }
        }

        &_th {
          display: block;
          min-width: 134px;
          text-align: center;
          border-bottom: 1px solid color.$site-main;
          padding: 12px 2px 13px;

          @include mixin.sp {
            min-width: 96px;
            padding: 12px 6px;
          }
        }

        &_td {
          display: block;
          width: 100%;
          margin: 0;
          border-bottom: 1px solid color.$site-main;
          padding: 12px 0 13px;

          @include mixin.sp {
            padding: 12px 6px;
          }

          & > :first-child {
            margin-top: 0;
          }

          &_text {
            margin: 11px 0 0;

            @include mixin.sp {
              margin-top: 8px;
            }
          }
        }
      }

      &_image {
        display: block;
        width: 208px;

        @include mixin.sp {
          display: none;
        }

        & > img {
          display: block;
          width: 100%;
        }
      }
    }

    &_map {
      display: block;
      width: 100%;
      height: auto;
      margin-top: 17px;

      @include mixin.pc {
        aspect-ratio: 1/0.426;
      }

      @include mixin.sp {
        margin-top: 20px;
      }

      iframe {
        display: block;

        @include mixin.pc {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        @include mixin.sp {
          width: 100% !important;
          height: auto !important;
          aspect-ratio: 1/0.852;
        }
      }
    }

    .main_linkButton {
      text-align: center;
    }
  }
}
